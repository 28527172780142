<template>
    <div class="hc41Box">
        <div
            class="hbBox1"
            v-if="getAdvData(26).children"
            :style="`background: url(${getAdvData(26).children[0].image}) no-repeat center / auto 100%;`"
        >
            <a :href="getAdvData(26).children[0].url" target="_blank" rel="noopener noreferrer">
                <div class="hb1Text">
                    <p class="ht1">明明才华洋溢 别人却把你当空气</p>
                    <div class="ht2">
                        <p class="h21">威有科技</p>
                        <p class="h22">推广服务</p>
                    </div>
                    <p class="ht3">让您的网站排名靠前，不再被忽视</p>
                </div>
            </a>
        </div>
        <div class="hbBox2">
            <p class="hb21">SEM广告投放服务</p>
            <p class="hb22">
                除了自然优化，威有科技还提供搜索引擎广告投放服务，<br />
                通过竞价排名完成企业的商业推广，让企业内容位于搜索首页。现有的投放服务<br />
                包括SEM代运营与信息流推广等。
            </p>
        </div>
        <div class="hbBox3">
            <p class="hb3Top">SEM广告投放服务内容</p>
            <div class="hb3Box">
                <div class="h3bLeft">
                    <p class="h3l1">01</p>
                    <p class="h3l2">提升咨询总量（订单总量）</p>
                    <p class="h3l3">
                        原昇营销已经帮助大量企业的威有竞价广告投入不变的情况下把咨询总量（订单总量）在原有基础上提升30%以上
                    </p>
                    <div class="h3l4">
                        <img src="http://iv.okvu.cn/vugw/img/hc4221.png" alt="" class="hl4Img" />
                        &nbsp; &nbsp; &nbsp;提升30%以上
                    </div>
                </div>
                <div class="h3bRight">
                    <img src="http://iv.okvu.cn/vugw/img/hc4222.png" alt="" class="h3rImg" />
                </div>
            </div>
        </div>
        <div class="hbBox4">
            <div class="hb4Box">
                <div class="h4bLeft">
                    <img src="http://iv.okvu.cn/vugw/img/hc4223.png" alt="" class="h4rImg" />
                </div>
                <div class="h4bRight">
                    <p class="h4l1">02</p>
                    <p class="h4l2">控制与节约无效广告投入</p>
                    <p class="h4l3">
                        任何一个威有竞价成本高效果差的账号都至少10%-20% <br />
                        的广告费浪费，原昇营销每天都会帮助合作企业做的一件事情就是找到浪费
                        <br />
                        的广告投入并及时加以控制
                    </p>
                </div>
            </div>
        </div>
        <div class="hbBox5">
            <div class="hb5Box">
                <div class="hb5Left">
                    <p class="h5l1">03</p>
                    <p class="h5l2">构建广告效果体侧体系</p>
                    <p class="h5l3">
                        只有构建起系统化的投放数据监控体系，才能够制定科学的优化策略。原昇
                        <br />
                        营销已经帮助每一个合作企业构建起系统科学的广告下过镜框体系
                    </p>
                </div>
                <div class="hb5Right">
                    <img src="http://iv.okvu.cn/vugw/img/hc4224.png" alt="" class="h5rImg" />
                </div>
            </div>
        </div>
        <div class="hbBox6">
            <div class="hb4Box">
                <div class="h4bLeft">
                    <img src="http://iv.okvu.cn/vugw/img/hc4225.png" alt="" class="h4rImg" />
                </div>
                <div class="h4bRight">
                    <p class="h4l1">04</p>
                    <p class="h4l2">提升质量度得分，降低点击价格</p>
                    <p class="h4l3">
                        用比同行更低的价格获得优质的客户，是每一个企业开展<br />
                        威有付费推广的理想状态，原昇营销通过帮助企业提高关键词质量度得分，
                        <br />
                        从而降低平均点击价格
                    </p>
                </div>
            </div>
        </div>
        <div class="hbBox7">
            <div class="hb5Box">
                <div class="hb5Left">
                    <p class="h5l1">05</p>
                    <p class="h5l2">排名与出价合理化、科学化</p>
                    <p class="h5l3">
                        什么位置的排名才是投资汇报了最高的排名，
                        <br />
                        什么样的关键词出家才是最合理的出家，原昇营销用一套最科学的评估方法
                        <br />帮助企业进行去吧化出价与差异化排名
                    </p>
                </div>
                <div class="hb5Right">
                    <img src="http://iv.okvu.cn/vugw/img/hc4226.png" alt="" class="h5rImg" />
                </div>
            </div>
        </div>
        <div class="hbBox8">
            <div class="hb4Box">
                <div class="h4bLeft">
                    <img src="http://iv.okvu.cn/vugw/img/hc4227.png" alt="" class="h4rImg" />
                </div>
                <div class="h4bRight">
                    <p class="h4l1">06</p>
                    <p class="h4l2">优化账户架构</p>
                    <p class="h4l3">
                        账户分组架构不仅影响着关键词的质量度得分，<br />
                        影响着广告创意点击率，同事也严重影响后期的数据分析，因此原昇营销会
                        <br />
                        帮助企业进行账户架构的优化
                    </p>
                </div>
            </div>
        </div>
        <div class="hbBox9">
            <div class="hb9Top">威有科技优点</div>
            <div class="hb9Box">
                <div class="h9Left">
                    <img src="http://iv.okvu.cn/vugw/img/hc4231.png" alt="" class="h9lImg" />
                </div>
                <div class="h9Right">
                    <p class="h9r1">有技术，更有服务态度</p>
                    <div class="h9r2"><span>15年</span> 网站建设品牌，<span>30000</span> 合作客户案例</div>
                    <div class="h9r3">
                        <ul>
                            <li class="h93Li" v-for="item in 3" :key="item">多种配套服务 <br />一站式解决您的建站难题</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HsContent41",
    inject: ["getAdvData"],
};
</script>

<style lang="less" scoped>
.hc41Box {
    width: 100%;
    margin-top: 78px;
    text-align: left;
    .hbBox1 {
        width: 100%;
        height: 470px;
        overflow: hidden;
        & > a {
            display: block;
            width: 100%;
            height: 100%;
            padding-top: 120px;
        }
        .hb1Text {
            max-width: 1280px;
            height: 232px;
            color: white;
            margin: 0 auto;
            .ht1 {
                height: 100%;
                height: 100px;
                line-height: 100px;
                font-size: 44px;
            }
            .ht2 {
                width: 100%;
                height: 30px;
                margin-top: 15px;
                .h21 {
                    width: 104px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 26px;
                    float: left;
                }
                .h22 {
                    width: 104px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 26px;
                    float: left;
                    margin-left: 140px;
                }
            }
            .ht3 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 26px;
                margin-top: 30px;
            }
        }
    }
    .hbBox2 {
        width: 100%;
        height: 293px;
        .hb21 {
            max-width: 1280px;
            height: 85px;
            line-height: 85px;
            text-align: center;
            font-size: 37px;
            font-weight: 900;
            color: rgb(119 129 241);
            margin: 0 auto;
            margin-top: 50px;
        }
        .hb22 {
            max-width: 1280px;
            height: 90px;
            line-height: 30px;
            text-align: center;
            font-size: 18px;
            margin: 0 auto;
        }
    }
    .hbBox3 {
        width: 100%;
        height: 450px;
        overflow: hidden;
        background-color: #f9f9f9;
        .hb3Top {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 30px;
            text-align: center;
            margin-top: 20px;
        }
        .hb3Box {
            max-width: 1280px;
            height: 376px;
            margin: 0 auto;
            .h3bLeft {
                width: 540px;
                height: 100%;
                float: left;
                .h3l1 {
                    display: block;
                    line-height: 108px;
                    font-size: 130px;
                    color: #eee;
                }
                .h3l2 {
                    width: 100%;
                    height: 53px;
                    line-height: 53px;
                    font-size: 24px;
                    color: rgb(119 129 241);
                    margin-top: 5px;
                }
                .h3l3 {
                    width: 100%;
                    height: 60px;
                    line-height: 30px;
                    margin-top: 10px;
                }
                .h3l4 {
                    width: 100%;
                    height: 55px;
                    color: #ff6600;
                    font-size: 18px;
                    padding-top: 20px;
                    box-sizing: border-box;
                    .hl4Img {
                        width: 400px;
                        height: 12px;
                        float: left;
                        margin-top: 5px;
                    }
                }
            }
            .h3bRight {
                width: 540px;
                height: 100%;
                float: right;
                .h3rImg {
                    margin-top: 100px;
                    margin-left: 50px;
                }
            }
        }
    }
    .hbBox4 {
        width: 100%;
        height: 351px;
        .hb4Box {
            max-width: 1280px;
            height: 251px;
            margin: 0 auto;
            margin-top: 50px;
            .h4bLeft {
                width: 540px;
                height: 100%;
                float: left;
                .h4rImg {
                    margin-top: 5px;
                }
            }
            .h4bRight {
                width: 540px;
                height: 100%;
                float: right;
                text-align: right;
                .h4l1 {
                    display: block;
                    line-height: 108px;
                    font-size: 130px;
                    color: #eee;
                }
                .h4l2 {
                    width: 100%;
                    height: 53px;
                    line-height: 53px;
                    font-size: 24px;
                    color: rgb(119 129 241);
                    margin-top: 5px;
                }
                .h4l3 {
                    width: 100%;
                    height: 90px;
                    margin-top: 10px;
                }
            }
        }
    }
    .hbBox5 {
        width: 100%;
        height: 379px;
        background-color: #f9f9f9;
        overflow: hidden;
        .hb5Box {
            max-width: 1280px;
            height: 279px;
            margin: 0 auto;
            margin-top: 50px;
            .hb5Left {
                width: 540px;
                height: 100%;
                float: left;
                .h5l1 {
                    display: block;
                    line-height: 108px;
                    font-size: 130px;
                    color: #eee;
                }
                .h5l2 {
                    width: 100%;
                    height: 53px;
                    line-height: 53px;
                    font-size: 24px;
                    color: rgb(119 129 241);
                    margin-top: 5px;
                }
                .h5l3 {
                    width: 100%;
                    height: 60px;
                    line-height: 30px;
                    margin-top: 10px;
                }
            }

            .hb5Right {
                width: 540px;
                height: 100%;
                float: right;
                .h5rImg {
                    margin-left: 100px;
                }
            }
        }
    }
    .hbBox6 {
        width: 100%;
        height: 351px;
        .hb4Box {
            max-width: 1280px;
            height: 251px;
            margin: 0 auto;
            margin-top: 50px;
            .h4bLeft {
                width: 540px;
                height: 100%;
                float: left;
                .h4rImg {
                    margin-top: 5px;
                }
            }
            .h4bRight {
                width: 540px;
                height: 100%;
                float: right;
                text-align: right;
                .h4l1 {
                    display: block;
                    line-height: 108px;
                    font-size: 130px;
                    color: #eee;
                }
                .h4l2 {
                    width: 100%;
                    height: 53px;
                    line-height: 53px;
                    font-size: 24px;
                    color: rgb(119 129 241);
                    margin-top: 5px;
                }
                .h4l3 {
                    width: 100%;
                    height: 90px;
                    margin-top: 10px;
                }
            }
        }
    }
    .hbBox7 {
        width: 100%;
        height: 379px;
        background-color: #f9f9f9;
        overflow: hidden;
        .hb5Box {
            max-width: 1280px;
            height: 279px;
            margin: 0 auto;
            margin-top: 50px;
            .hb5Left {
                width: 540px;
                height: 100%;
                float: left;
                .h5l1 {
                    display: block;
                    line-height: 108px;
                    font-size: 130px;
                    color: #eee;
                }
                .h5l2 {
                    width: 100%;
                    height: 53px;
                    line-height: 53px;
                    font-size: 24px;
                    color: rgb(119 129 241);
                    margin-top: 5px;
                }
                .h5l3 {
                    width: 100%;
                    height: 90px;
                    line-height: 30px;
                    margin-top: 10px;
                }
            }

            .hb5Right {
                width: 540px;
                height: 100%;
                float: right;
                .h5rImg {
                    margin-left: 100px;
                }
            }
        }
    }
    .hbBox8 {
        width: 100%;
        height: 351px;
        .hb4Box {
            max-width: 1280px;
            height: 251px;
            margin: 0 auto;
            margin-top: 50px;
            .h4bLeft {
                width: 540px;
                height: 100%;
                float: left;
                .h4rImg {
                    margin-top: 5px;
                }
            }
            .h4bRight {
                width: 540px;
                height: 100%;
                float: right;
                text-align: right;
                .h4l1 {
                    display: block;
                    line-height: 108px;
                    font-size: 130px;
                    color: #eee;
                }
                .h4l2 {
                    width: 100%;
                    height: 53px;
                    line-height: 53px;
                    font-size: 24px;
                    color: rgb(119 129 241);
                    margin-top: 5px;
                }
                .h4l3 {
                    width: 100%;
                    height: 90px;
                    margin-top: 10px;
                }
            }
        }
    }
    .hbBox9 {
        width: 100%;
        height: 540px;
        overflow: hidden;
        .hb9Top {
            max-width: 1280px;
            height: 112px;
            line-height: 112px;
            margin: 0 auto;
            font-size: 37px;
            font-weight: 900;
            text-align: center;
            border-bottom: 1px solid gainsboro;
        }
        .hb9Box {
            max-width: 1280px;
            height: 308px;
            margin: 0 auto;
            margin-top: 50px;
            .h9Left {
                width: 450px;
                height: 300px;
                overflow: hidden;
                border-radius: 10px;
                float: left;
                .h9lImg {
                    width: 100%;
                    height: 100%;
                }
            }

            .h9Right {
                width: 680px;
                height: 308px;
                float: right;
                .h9r1 {
                    width: 100%;
                    height: 36px;
                    line-height: 36px;
                    font-size: 24px;
                    color: rgb(119 129 241);
                }
                .h9r2 {
                    width: 100%;
                    height: 47px;
                    line-height: 47px;
                    font-size: 20px;
                    span {
                        height: 33px;
                        color: #ff6600;
                        font-size: 30px;
                    }
                }
                .h9r3 {
                    width: 100%;
                    height: 195px;
                    margin-top: 20px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        background: url("http://iv.okvu.cn/vugw/img/c_bc_mod9.png") left top no-repeat;
                        .h93Li {
                            width: 155px;
                            height: 52px;
                            font-size: 14px;
                            text-align: center;
                            margin-top: 150px;
                        }
                    }
                }
            }
        }
    }
}
</style>
